/*============================================================================*\
   Global SCSS configuration
\*============================================================================*/
/*============================================================================*\
   Assets
\*============================================================================*/
/*============================================================================*\
   Typography
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/*============================================================================*\
   Layers
\*============================================================================*/
/*============================================================================*\
   Main entry point
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Enable of disable the helper classes */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/*============================================================================*\
   Function helper
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @return {string}                  A media query expression
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/*============================================================================*\
   Color helpers
\*============================================================================*/
/*============================================================================*\
   SVG Color helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/**
 * Definition of the space rules
 */
/*============================================================================*\
   Helper function
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Space gutter
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-x2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-x4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/*============================================================================*\
   Helper mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 * @source http://cloud.meta.fr/8fdbea50b775
 */
/*============================================================================*\
   Helper classes
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/**
 * A map to define all font-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * The `fz($font-size, $unit)` and the `lh($font-size)` functions below can be
 * used to get easily one of the two values.
 *
 * @type {Map}
 */
/*============================================================================*\
   Font-sizes helper function
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/**
 * A function helper to get the computed line-height of the given font-size
 * @param  {string} $font-size The name of the font-size
 * @return {string}            The corresponding line-height
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 * @param  {string} $font-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/*============================================================================*\
   Fontaces declarations
\*============================================================================*/
/*============================================================================*\
   Responsive type mixin
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-font  The minimum font-size
 * @param  {integer} $max-font  The maximum font-size
 * @return {void}
 */
/*============================================================================*\
   Antialiasing mixin
\*============================================================================*/
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type font-size helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement helpers
\*============================================================================*/
/*============================================================================*\
   Type font families helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/*============================================================================*\
   Type spacing
\*============================================================================*/
/*============================================================================*\
   Type transform
\*============================================================================*/
/*============================================================================*\
   Type font defaults
\*============================================================================*/
/*============================================================================*\
   UI spacings
\*============================================================================*/
/*==========================================================================*\
   Loader block
\*==========================================================================*/
.loader {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px;
}

/* Animation modifiers
\*==========================================================================*/
.loader--leave-to {
  opacity: 0;
  transform: scale(0);
}
.loader--leave-active {
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*==========================================================================*\
   Progress element
\*==========================================================================*/
.loader__stroke {
  fill: none;
}
.loader__stroke--back {
  stroke: #eee;
  stroke-dasharray: 376.956;
  stroke-dashoffset: 0;
}
html.is-dark .loader__stroke--back,
  .loader--dark .loader__stroke--back {
    stroke: #828299;
    opacity: 0.25;
}
.loader__stroke--front {
  stroke: #ba9f79 !important;
  stroke-dasharray: 376.956;
  stroke-dashoffset: 376.956;
}
