/*============================================================================*\
   Global SCSS configuration
\*============================================================================*/
/*============================================================================*\
   Assets
\*============================================================================*/
/*============================================================================*\
   Typography
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/*============================================================================*\
   Layers
\*============================================================================*/
/*============================================================================*\
   Main entry point
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Enable of disable the helper classes */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/*============================================================================*\
   Function helper
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @return {string}                  A media query expression
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/*============================================================================*\
   Color helpers
\*============================================================================*/
/*============================================================================*\
   SVG Color helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/**
 * Definition of the space rules
 */
/*============================================================================*\
   Helper function
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Space gutter
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-x2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-x4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/*============================================================================*\
   Helper mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 * @source http://cloud.meta.fr/8fdbea50b775
 */
/*============================================================================*\
   Helper classes
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/**
 * A map to define all font-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * The `fz($font-size, $unit)` and the `lh($font-size)` functions below can be
 * used to get easily one of the two values.
 *
 * @type {Map}
 */
/*============================================================================*\
   Font-sizes helper function
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/**
 * A function helper to get the computed line-height of the given font-size
 * @param  {string} $font-size The name of the font-size
 * @return {string}            The corresponding line-height
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 * @param  {string} $font-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/*============================================================================*\
   Fontaces declarations
\*============================================================================*/
/*============================================================================*\
   Responsive type mixin
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-font  The minimum font-size
 * @param  {integer} $max-font  The maximum font-size
 * @return {void}
 */
/*============================================================================*\
   Antialiasing mixin
\*============================================================================*/
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type font-size helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement helpers
\*============================================================================*/
/*============================================================================*\
   Type font families helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/*============================================================================*\
   Type spacing
\*============================================================================*/
/*============================================================================*\
   Type transform
\*============================================================================*/
/*============================================================================*\
   Type font defaults
\*============================================================================*/
/*============================================================================*\
   UI spacings
\*============================================================================*/
* {
  cursor: none !important;
}

/*==========================================================================*\
   App block
\*==========================================================================*/
.app {
  --c-primary: #222;
  --c-secondary: #fff;
  --c-tertiary: #aaa;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0;
  color: var(--c-primary);
}
.app:not(.app--loading) {
    background-color: var(--c-secondary);
}
@media all and (min-width: 0em) {
.app {
      padding: 1.5rem;
}
}
@media all and (min-width: 30em) {
.app {
      padding: 2rem;
}
}
@media all and (min-width: 48em) {
.app {
      padding: 3rem;
}
}
@media all and (min-width: 80em) {
.app {
      padding: 5rem;
}
}
@media all and (min-width: 120em) {
.app {
      padding: 6.5rem;
}
}
.app__main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Dark theme modifier
\*==========================================================================*/
.app--dark-theme {
  --c-primary: #fff;
  --c-secondary: #0C0C14;
  --c-tertiary: #828299;
}

/* Transition modifiers
\*==========================================================================*/
.app--enter,
.app--leave-to {
  opacity: 0;
}
.app--enter .app__logo,
  .app--enter .app__view,
  .app--enter .app__footer-item,
  .app--enter .app__language-switch,
  .app--leave-to .app__logo,
  .app--leave-to .app__view,
  .app--leave-to .app__footer-item,
  .app--leave-to .app__language-switch {
    opacity: 0;
    transform: translate(0, 100px);
}
.app--enter-active,
.app--leave-active {
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.app--enter-active .app__logo,
  .app--enter-active .app__view,
  .app--enter-active .app__footer-item,
  .app--enter-active .app__language-switch,
  .app--leave-active .app__logo,
  .app--leave-active .app__view,
  .app--leave-active .app__footer-item,
  .app--leave-active .app__language-switch {
    transition: all 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.app--enter-active .app__language-switch,
  .app--leave-active .app__language-switch {
    transition-delay: 0.1s;
}
.app--enter-active .app__footer-item:nth-child(1),
  .app--leave-active .app__footer-item:nth-child(1) {
    transition-delay: 0.1s;
}
.app--enter-active .app__footer-item:nth-child(2),
  .app--leave-active .app__footer-item:nth-child(2) {
    transition-delay: 0.2s;
}
.app--enter-active .app__footer-item:nth-child(3),
  .app--leave-active .app__footer-item:nth-child(3) {
    transition-delay: 0.3s;
}

/*==========================================================================*\
   App header
\*==========================================================================*/
.app__header {
  z-index: 101;
  position: relative;
  text-align: center;
  pointer-events: none;
}

/*==========================================================================*\
   App header inner
\*==========================================================================*/
.app__header-inner {
  position: relative;
}

/*==========================================================================*\
   App logo
\*==========================================================================*/
.app__logo {
  display: inline-block;
  pointer-events: all;
}

/*==========================================================================*\
   Language switch
\*==========================================================================*/
.app__language-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 30em) {
.app__language-switch {
      position: absolute;
      top: 0;
      right: 0;
}
}

/*==========================================================================*\
   App View
\*==========================================================================*/
.app__view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Date modifier
\*==========================================================================*/
.app__view--date {
  opacity: 1 !important;
}

/*==========================================================================*\
   App footer
\*==========================================================================*/
.app__footer {
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}
.app__footer .icon--instagram svg {
    width: 24px;
    height: 24px;
}

/*==========================================================================*\
   Footer item
\*==========================================================================*/
.app__footer-item {
  white-space: nowrap;
}
@media (min-width: 30em) {
.app__footer-item {
      width: 33%;
      white-space: initial;
}
.app__footer-item:first-child {
        text-align: left;
}
.app__footer-item:only-child, .app__footer-item:nth-child(2) {
        text-align: center;
}
.app__footer-item:last-child {
        text-align: right;
}
}

/*==========================================================================*\
   Footer link
\*==========================================================================*/
.app__footer-link {
  color: var(--c-tertiary);
  transition: color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: all;
}
.app__footer-link:hover {
    color: var(--c-primary);
}
@media (min-width: 30em) {
.app__footer-rules {
    display: flex;
    align-items: center;
}
.icon--info {
    margin-right: 0.6em;
}
}
.intro__content {
  max-width: 24em;
  margin-right: auto;
  margin-left: auto;
}

/*==========================================================================*\
   Page transition for index & intro
\*==========================================================================*/
.page-enter .fade,
.page-leave-to .fade {
  opacity: 0;
}
.page-enter-active .fade,
.page-leave-active .fade {
  transition: opacity 1.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/*==========================================================================*\
  Buttons
\*==========================================================================*/
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 20em;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #222;
  color: #fff;
  fill: #fff;
}
.btn__inner {
  font-size: 12px;
}
.btn--secondary {
  padding: 1rem 0;
  color: var(--c-primary);
  background-color: transparent;
  border: 1px solid currentColor;
}
