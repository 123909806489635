/*============================================================================*\
   Global SCSS configuration
\*============================================================================*/
/*============================================================================*\
   Assets
\*============================================================================*/
/*============================================================================*\
   Typography
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/*============================================================================*\
   Layers
\*============================================================================*/
/*============================================================================*\
   Main entry point
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Enable of disable the helper classes */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/*============================================================================*\
   Function helper
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @return {string}                  A media query expression
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/*============================================================================*\
   Color helpers
\*============================================================================*/
/*============================================================================*\
   SVG Color helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/**
 * Definition of the space rules
 */
/*============================================================================*\
   Helper function
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Space gutter
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-x2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-x4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/*============================================================================*\
   Helper mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 * @source http://cloud.meta.fr/8fdbea50b775
 */
/*============================================================================*\
   Helper classes
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/**
 * A map to define all font-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * The `fz($font-size, $unit)` and the `lh($font-size)` functions below can be
 * used to get easily one of the two values.
 *
 * @type {Map}
 */
/*============================================================================*\
   Font-sizes helper function
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/**
 * A function helper to get the computed line-height of the given font-size
 * @param  {string} $font-size The name of the font-size
 * @return {string}            The corresponding line-height
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 * @param  {string} $font-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/*============================================================================*\
   Fontaces declarations
\*============================================================================*/
/*============================================================================*\
   Responsive type mixin
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-font  The minimum font-size
 * @param  {integer} $max-font  The maximum font-size
 * @return {void}
 */
/*============================================================================*\
   Antialiasing mixin
\*============================================================================*/
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type font-size helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement helpers
\*============================================================================*/
/*============================================================================*\
   Type font families helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/*============================================================================*\
   Type spacing
\*============================================================================*/
/*============================================================================*\
   Type transform
\*============================================================================*/
/*============================================================================*\
   Type font defaults
\*============================================================================*/
/*============================================================================*\
   UI spacings
\*============================================================================*/
/*==========================================================================*\
  Cursor variables
\*==========================================================================*/
* {
  cursor: none !important;
  user-select: none !important;
}

/* Cursor block
\*==========================================================================*/
.cursor {
  z-index: 300;
  position: fixed;
  top: -56px;
  left: -56px;
  transform: translate(50%, 50%);
  pointer-events: none;
}

/*==========================================================================*\
   SVG element
\*==========================================================================*/
.cursor__svg {
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.cursor--is-day.cursor--is-touch .cursor__svg {
    opacity: 0;
    transform: scale(0);
}
.cursor__stroke {
  fill: none;
}

/* Circle front side
\*==========================================================================*/
.cursor__stroke--front {
  stroke: #ba9f79;
  stroke-dasharray: 351.85838;
  stroke-dashoffset: 0;
}

/* Circle back side
\*==========================================================================*/
.cursor__stroke--back {
  stroke: #aaa;
  stroke-dasharray: 351.85838;
  stroke-dashoffset: 0;
  transform-origin: center;
}

/* Circle fill
\*==========================================================================*/
.cursor__fill {
  fill: #ba9f79;
  transform: scale(0);
  transform-origin: center;
}

/* Back Arrow
\*==========================================================================*/
.cursor__arrow {
  padding-left: 0 !important;
  color: #fff;
}

/*==========================================================================*\
   Button element
\*==========================================================================*/
.cursor__btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.cursor__btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ba9f79;
    border-radius: 50%;
}
.cursor--is-touch .cursor__btn {
    display: none !important;
}
.cursor__btn--enter,
.cursor__btn--leave-to {
  opacity: 0;
}
.cursor__btn--enter::before,
  .cursor__btn--leave-to::before {
    transform: scale(0);
}
.cursor__btn--enter-active, .cursor__btn--enter-active::before,
.cursor__btn--leave-active,
.cursor__btn--leave-active::before {
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

/*==========================================================================*\
   Icon element
\*==========================================================================*/
.cursor__icon {
  z-index: 1;
  display: block;
  transform: rotate(180deg);
}
.cursor__btn--enter .cursor__icon,
  .cursor__btn--leave-to .cursor__icon {
    transform: rotate(180deg) scale(0, 1);
}
.cursor__btn--enter-active .cursor__icon,
  .cursor__btn--leave-active .cursor__icon {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

/* Animation on load
\*==========================================================================*/
@keyframes loading {
0% {
    stroke-dashoffset: 351.85838;
}
100% {
    stroke-dashoffset: 0;
}
}
@keyframes scale {
0% {
    transform: scale(0);
    opacity: 0;
}
100% {
    transform: scale(1);
    opacity: 1;
}
}
